import React from "react";
import { Tag } from "antd";

const PdTimeLocation = ({ text, active, onActive }) => (
  <Tag
    color="red"
    className={`pd-block ${active ? "active" : ""}`}
    onClick={onActive}
  >
    {text}
  </Tag>
);
export default PdTimeLocation;
