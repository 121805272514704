import React from "react";
import { Tooltip } from "antd";
import Moment from "moment";

const DetectLocation = ({ message }) => {
  let className = "detect-location";
  if (message.active) className += " active";
  if (message.highlight) className += " highlight";
  console.log(message);
  return (
    <Tooltip
      title={Moment(message.time).format("HH:mm:ss : ") + message.motorized}
    >
      <div className={className}></div>
    </Tooltip>
  );
};

export default DetectLocation;
