import React from "react";
import Moment from "moment";
import { Tag } from "antd";

class MessageItem extends React.Component {
  formatDatTime = dt => {
    return Moment(dt).format("HH:mm");
  };

  getTripStatus = code => {
    if (code === 1) return "START";
    if (code === 2) return "STOPPED";
    if (code === 3) return "MOVE";
    if (code === 4) return "NO MOTION";
    if (code === 5) return "LOAD TOGGLED";
    return "UNKNOWN";
  };

  getItemColor = message => {
    return message.highlight ? "#fa8c16" : "";
  };

  render = () => {
    const { message } = this.props;
    return (
      <div>
        <Tag color={this.getItemColor(message)}>
          {this.formatDatTime(message.time)}
        </Tag>
        <Tag color={this.getItemColor(message)}>
          {this.getTripStatus(message.tripStatus)}
        </Tag>
      </div>
    );
  };
}

export default MessageItem;
