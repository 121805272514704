import React from "react";
import GoogleMapReact from "google-map-react";
import axios from "axios";
import AirportLocation from "../components/AirportLocation";
import {
  Button,
  Collapse,
  DatePicker,
  Divider,
  Form,
  Icon,
  Input,
  Layout,
  Select,
  Timeline,
} from "antd";
import Moment from "moment";
import Polyline from "../components/Polyline";
import DetectLocation from "../components/DetectLocation";
import PdTimeLocation from "../components/PdTimeLocation";
import {
  LeftOutlined,
  RightOutlined,
  VerticalLeftOutlined,
  VerticalRightOutlined,
} from "@ant-design/icons";

const { Content, Sider } = Layout;

export class DnataMonitor extends React.Component {
  state = {
    center: {
      lat: 1.359167,
      lng: 103.989441,
    },
    zoom: 17,
    locations: [],
    messages: [],
    active: 0,
    date: Moment("2020-02-01"),
    flights: [],
    device: "PD585",
    map: null,
    maps: null,
    devices: [],
    period: 5 * 60,
    periodMessages: [],
    allPeriodLocations: [],
    index: 0,
    activeMotorized: "",
    activeDetectLocation: 0,
  };

  async componentWillMount() {
    await this.loadLocations();
    await this.loadDevices();
  }

  loadLocations = async () => {
    axios.get("/api/1.0/locations").then((response) => {
      this.setState({ locations: response.data });
    });
  };

  onDeviceChanged = (value) => {
    this.setState(
      {
        device: value,
      },
      this.loadMessages
    );
  };

  onMotorizedChanged = (value) => {
    this.setState({
      motorized: value,
    });
  };

  loadMessages = async () => {
    const deviceId = this.state.device;
    axios
      .get("/api/1.0/dnata/messages", {
        params: {
          device: deviceId,
          from: this.state.date.format("YYYY-MM-DD") + "T00:00:00",
          to: this.state.date.format("YYYY-MM-DD") + "T23:59:59",
        },
      })
      .then((response) => {
        this.setState(
          {
            messages: response.data,
          },
          this.loadAllPeriodLocations
        );
        this.loadFlights();
        this.onPeriodActive(0);
      });
  };

  onDateChanged = (value) => {
    this.setState(
      {
        date: value,
      },
      this.loadMessages
    );
  };

  onPeriodChanged = (value) => {
    this.setState({ period: value }, () => {
      this.loadAllPeriodLocations();
    });
  };

  loadFlights = async () => {
    const from = this.state.date.clone().subtract(1, "days");
    const to = this.state.date.clone().add(1, "days");
    axios
      .get("/api/1.0/flights", {
        params: {
          from: from.format("YYYY-MM-DD") + "T20:00:00",
          to: to.format("YYYY-MM-DD") + "T04:00:00",
        },
      })
      .then((response) => {
        this.setState({ flights: response.data });
      });
  };

  loadDevices = async () => {
    axios.get("/api/1.0/dnata/devices").then((response) => {
      this.setState({ devices: response.data }, this.loadMessages);
    });
  };

  loadAllPeriodLocations = () => {
    const date = this.state.date.format("YYYY-MM-DD");
    const from = Moment(date + "T00:00:00");
    const to = Moment(date + "T23:59:59");

    let datetime = from;
    const allPeriodLocations = [];
    while (datetime <= to) {
      const from = datetime.clone().subtract(this.state.period, "seconds");
      const to = datetime.clone().add(this.state.period, "seconds");
      const messages = this.state.messages.filter(
        (m) => Moment(m.time) >= from && Moment(m.time) <= to
      );
      const center = this.getCenter(messages);
      if (center) {
        allPeriodLocations.push({
          lat: center.lat,
          lng: center.lng,
          time: datetime,
          messages: messages,
        });
      }
      datetime = datetime.clone().add(this.state.period, "seconds");
    }
    this.setState({ allPeriodLocations });
  };

  onPeriodActive = (value) => {
    const totalPeriods = this.state.allPeriodLocations.length;
    if (totalPeriods === 0) {
      value = 0;
    } else {
      value = (value + totalPeriods) % totalPeriods;
    }
    this.setState({ index: value }, () => {
      const currentPeriodLocation = this.state.allPeriodLocations[
        this.state.index
      ];
      if (currentPeriodLocation) {
        this.setState({
          center: {
            lat: currentPeriodLocation.lat,
            lng: currentPeriodLocation.lng,
          },
        });
      }
    });
  };

  nextPeriod = () => {
    this.onPeriodActive(this.state.index + 1);
  };
  firstPeriod = () => {
    this.onPeriodActive(0);
  };
  lastPeriod = () => {
    this.onPeriodActive(this.state.allPeriodLocations.length - 1);
  };
  previousPeriod = () => {
    this.onPeriodActive(this.state.index - 1);
  };
  getCenter = (data) => {
    if (!(data.length > 0)) {
      return false;
    }

    let num_coords = data.length;

    let X = 0.0;
    let Y = 0.0;
    let Z = 0.0;

    for (let i = 0; i < data.length; i++) {
      let lat = (data[i].lat * Math.PI) / 180;
      let lon = (data[i].lng * Math.PI) / 180;

      let a = Math.cos(lat) * Math.cos(lon);
      let b = Math.cos(lat) * Math.sin(lon);
      let c = Math.sin(lat);

      X += a;
      Y += b;
      Z += c;
    }

    X /= num_coords;
    Y /= num_coords;
    Z /= num_coords;

    let lon = Math.atan2(Y, X);
    let hyp = Math.sqrt(X * X + Y * Y);
    let lat = Math.atan2(Z, hyp);

    let newX = (lat * 180) / Math.PI;
    let newY = (lon * 180) / Math.PI;

    return { lat: newX, lng: newY };
  };

  onActiveMotorizedChanged = (key) => {
    if (!key) key = "";
    this.setState({ activeMotorized: key });
  };

  render() {
    const currentPeriodLocation = this.state.allPeriodLocations[
      this.state.index
    ];
    const messages = currentPeriodLocation?.messages;

    const groupByMotorized = {};
    if (messages) {
      messages.forEach((m) => {
        let motorized = m.motorized;
        if (!motorized) motorized = "unknown";
        m.motorized = motorized;

        m.active = motorized === this.state.activeMotorized;
        m.highlight = m.id === this.state.activeDetectLocation;

        let groupMessages = groupByMotorized[motorized];
        if (!groupMessages) groupMessages = [];
        groupMessages.push(m);
        groupByMotorized[motorized] = groupMessages;
      });
    }

    return (
      <div className="dnata-monitor">
        <Layout>
          <Content>
            <div className="map-wrapper">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyB97WpsEkG4BvChG2taq9UtC_urZiHGXFI",
                }}
                center={this.state.center}
                defaultZoom={this.state.zoom}
                options={{ fullscreenControl: false }}
                onGoogleApiLoaded={({ map, maps }) => {
                  this.setState({ map: map, maps: maps, mapLoaded: true });
                }}
                yesIWantToUseGoogleMapApiInternals
              >
                {this.state.locations.map((l) => {
                  return (
                    <AirportLocation
                      lat={l.lat}
                      lng={l.lng}
                      location={l}
                      flights={this.state.flights.filter(
                        (f) => f.tar === l.standNr
                      )}
                      key={l.standNr}
                    />
                  );
                })}
                {this.state.allPeriodLocations.map((m, i) => {
                  return (
                    <PdTimeLocation
                      text={m.time.format("HH:mm")}
                      lat={m.lat}
                      lng={m.lng}
                      key={m.time}
                      active={i === this.state.index}
                      onActive={() => this.onPeriodActive(i)}
                    />
                  );
                })}
                {messages &&
                  messages.map((m) => {
                    return (
                      <DetectLocation
                        message={m}
                        color="#1890FF"
                        lat={m.lat}
                        lng={m.lng}
                        key={m.id}
                      />
                    );
                  })}
              </GoogleMapReact>
              {this.state.mapLoaded && (
                <Polyline
                  paths={this.state.allPeriodLocations}
                  map={this.state.map}
                  maps={this.state.maps}
                  color="#ff4d4f"
                />
              )}
              {this.state.mapLoaded && (
                <Polyline
                  paths={groupByMotorized[this.state.activeMotorized]}
                  map={this.state.map}
                  maps={this.state.maps}
                  color="#52c41a"
                />
              )}
            </div>
          </Content>
          <Sider
            theme="light"
            width="250"
            style={{ paddingLeft: 20, paddingTop: 20, paddingRight: 20 }}
          >
            <Form size="small" layout="vertical">
              <Form.Item label="Please select pallet dolly" size="small">
                <Select
                  style={{ width: 200 }}
                  suffixIcon={
                    <Icon type="car" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  showSearch
                  placeholder="Select a pallet dolly"
                  optionFilterProp="children"
                  onSelect={this.onDeviceChanged}
                  value={this.state.device}
                >
                  {this.state.devices.map((value, index) => {
                    return (
                      <Select.Option
                        key={value.deviceId}
                        value={value.deviceId}
                      >
                        {value.deviceId}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item label="Please select date">
                <DatePicker
                  style={{ width: 200 }}
                  allowClear={false}
                  value={this.state.date}
                  onChange={this.onDateChanged}
                  disabledDate={this.disabledDate}
                />
              </Form.Item>
              <Form.Item label="Load GPSs for +/- Mins">
                <Select
                  style={{ width: 200 }}
                  value={this.state.period}
                  onChange={this.onPeriodChanged}
                >
                  <Select.Option value={60}> 1 min </Select.Option>
                  <Select.Option value={300}> 5 mins </Select.Option>
                  <Select.Option value={600}> 10 mins </Select.Option>
                  <Select.Option value={900}> 15 mins </Select.Option>
                  <Select.Option value={1200}> 20 mins </Select.Option>
                  <Select.Option value={1500}> 25 mins </Select.Option>
                  <Select.Option value={1800}> 30 mins </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label="Period Controller">
                <Button
                  size="small"
                  style={{ marginRight: 5 }}
                  onClick={this.firstPeriod}
                >
                  <VerticalRightOutlined />
                </Button>
                <Button
                  size="small"
                  style={{ marginRight: 5 }}
                  onClick={this.previousPeriod}
                >
                  <LeftOutlined />
                </Button>
                <Input
                  size="small"
                  style={{ width: 60 }}
                  value={currentPeriodLocation?.time.format("HH:mm")}
                />
                <Button
                  size="small"
                  style={{ marginRight: 5 }}
                  onClick={this.nextPeriod}
                >
                  <RightOutlined />
                </Button>
                <Button size="small" onClick={this.lastPeriod}>
                  <VerticalLeftOutlined />
                </Button>
              </Form.Item>
            </Form>
            <Divider style={{ margin: "8px 0 15px 0" }} />
            <div className="tractor-view">
              <Collapse onChange={this.onActiveMotorizedChanged} accordion>
                {Object.keys(groupByMotorized).map((motorized) => {
                  return (
                    <Collapse.Panel
                      header={
                        motorized +
                        " (" +
                        groupByMotorized[motorized].length +
                        ")"
                      }
                      key={motorized}
                    >
                      <Timeline>
                        {groupByMotorized[motorized].map((m) => (
                          <Timeline.Item
                            onMouseOver={() =>
                              this.setState({ activeDetectLocation: m.id })
                            }
                          >
                            <span
                              style={{
                                fontWeight: m.highlight ? "bold" : "normal",
                              }}
                            >
                              {Moment(m.time).format("HH:mm:ss : ") +
                                m.motorized}
                            </span>
                          </Timeline.Item>
                        ))}
                      </Timeline>
                    </Collapse.Panel>
                  );
                })}
              </Collapse>
            </div>
          </Sider>
        </Layout>
      </div>
    );
  }
}

export default DnataMonitor;
