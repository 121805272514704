import React from "react";
import { Tag } from "antd";

const PdLocation = ({ text, loaded, active }) => (
  <Tag
    color={loaded === 1 ? "blue" : "red"}
    className={`pd-block ${active ? "active" : ""}`}
  >
    {text}
  </Tag>
);

export default PdLocation;
