import React from "react";
import { Popover, Timeline } from "antd";

const AirportLocation = ({ location, flights }) => (
  <Popover
    placement="top"
    title={location.location + " (" + location.standNr + ")"}
    content={
      <Timeline style={{ marginTop: "10px" }}>
        {flights.map((f, i) => (
          <Timeline.Item color={f.rcg === "ARR" ? "blue" : "red"} key={i}>
            {f.description}
          </Timeline.Item>
        ))}
      </Timeline>
    }
  >
    <div>{location.standNr}</div>
  </Popover>
);

export default AirportLocation;
