import React from "react";
import GoogleMapReact from "google-map-react";
import axios from "axios";
import AirportLocation from "../components/AirportLocation";
import PdLocation from "../components/PdLocation";
import MessageTimeline from "../components/MessageTimeline";
import {
  Form,
  Icon,
  Select,
  DatePicker,
  Layout,
  Divider,
  Checkbox
} from "antd";
import Moment from "moment";
const { Content, Sider, Header } = Layout;

export class DeviceMonitor extends React.Component {
  state = {
    center: {
      lat: 1.359167,
      lng: 103.989441
    },
    zoom: 17,
    locations: [],
    messages: [],
    active: 0,
    date: Moment(),
    devices: [],
    selectedDeviceIdx: 0,
    flights: [],
    ifHighlightLoadToggledBlock: false
  };

  async componentWillMount() {
    await this.loadLocations();
    await this.loadDevices();
  }

  loadLocations = async () => {
    axios.get("/api/1.0/locations").then(response => {
      this.setState({ locations: response.data });
    });
  };

  loadDeviceMessages = async () => {
    const deviceId = this.state.devices[this.state.selectedDeviceIdx].deviceId;
    axios
      .get("/api/1.0/devices/messages", {
        params: {
          deviceId: deviceId,
          from: this.state.date.format("YYYY-MM-DD") + "T00:00:00",
          to: this.state.date.format("YYYY-MM-DD") + "T23:59:59"
        }
      })
      .then(response => {
        this.setState(
          { messages: response.data },
          this.highlightLoadToggledBlock
        );
        this.loadFlights();
      });
  };

  highlightLoadToggledBlock = () => {
    let messages = [...this.state.messages];
    for (let message of messages) {
      message.highlight =
        this.state.ifHighlightLoadToggledBlock && message.block > 0;
    }
    this.setState({ messages: messages });
  };

  loadFlights = async () => {
    const from = this.state.date.clone().subtract(1, "days");
    const to = this.state.date.clone().add(1, "days");
    axios
      .get("/api/1.0/flights", {
        params: {
          from: from.format("YYYY-MM-DD") + "T20:00:00",
          to: to.format("YYYY-MM-DD") + "T04:00:00"
        }
      })
      .then(response => {
        this.setState({ flights: response.data });
      });
  };

  loadDevices = async () => {
    axios.get("/api/1.0/devices").then(response => {
      this.setState({ devices: response.data }, this.loadDeviceMessages);
    });
  };

  onMessageActive = message => {
    if (message.gpsAvailable) {
      this.setState({
        active: message,
        center: {
          lat: message.lat,
          lng: message.lng
        }
      });
    }
  };

  onDeviceChanged = value => {
    this.setState(
      {
        selectedDeviceIdx: value
      },
      this.loadDeviceMessages
    );
  };

  onDateChanged = value => {
    this.setState(
      {
        date: value
      },
      this.loadDeviceMessages
    );
  };

  onHighlightLoadToggledBlockChanged = e => {
    this.setState(
      {
        ifHighlightLoadToggledBlock: e.target.checked
      },
      this.highlightLoadToggledBlock
    );
  };

  disabledDate = current => {
    const { fromTime, toTime } = this.state.devices[
      this.state.selectedDeviceIdx
    ];
    return (
      current &&
      (current < Moment(fromTime).startOf("day") ||
        current > Moment(toTime).endOf("day"))
    );
  };

  render() {
    return (
      <div className="device-monitor">
        <Layout>
          <Content>
            <div className="map-wrapper">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyB97WpsEkG4BvChG2taq9UtC_urZiHGXFI"
                }}
                center={this.state.center}
                defaultZoom={this.state.zoom}
                options={{ fullscreenControl: false }}
              >
                {this.state.locations.map(l => {
                  return (
                    <AirportLocation
                      lat={l.lat}
                      lng={l.lng}
                      location={l}
                      flights={this.state.flights.filter(
                        f => f.tar === l.standNr
                      )}
                      key={l.standNr}
                    />
                  );
                })}
                {this.state.messages.map(value => {
                  return (
                    value.gpsAvailable && (
                      <PdLocation
                        lat={value.lat}
                        lng={value.lng}
                        text={value.adjustSeqNo}
                        loaded={value.loadStatus}
                        active={this.state.active.seqNo === value.seqNo}
                        key={value.seqNo}
                      />
                    )
                  );
                })}
              </GoogleMapReact>
            </div>
          </Content>
          <Sider theme="light" width="400">
            <Layout>
              <Header
                style={{
                  background: "#fff",
                  width: "100%",
                  padding: "15px 0 0 30px",
                  height: "90px"
                }}
              >
                <Form
                  layout="inline"
                  style={{
                    textAlign: "leftttt"
                  }}
                >
                  <Form.Item>
                    <Select
                      suffixIcon={
                        <Icon type="car" style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      showSearch
                      style={{ width: "150px" }}
                      placeholder="Select a device"
                      optionFilterProp="children"
                      onSelect={this.onDeviceChanged}
                      value={this.state.selectedDeviceIdx}
                    >
                      {this.state.devices.map((value, index) => {
                        return (
                          <Select.Option key={index} value={index}>
                            {value.deviceId}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <DatePicker
                      allowClear={false}
                      style={{ width: "150px" }}
                      value={this.state.date}
                      onChange={this.onDateChanged}
                      disabledDate={this.disabledDate}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Checkbox
                      checked={this.state.ifHighlightLoadToggledBlock}
                      onChange={this.onHighlightLoadToggledBlockChanged}
                    >
                      Highlight loaded to unloaded block
                    </Checkbox>
                  </Form.Item>
                </Form>
              </Header>
              <Content style={{ background: "#fff" }}>
                <Divider style={{ margin: "8px 0 15px 0" }} />
                <div className="list-wrapper">
                  <MessageTimeline
                    messages={this.state.messages}
                    activeMessage={this.state.active}
                    onMessageActive={this.onMessageActive}
                  />
                </div>
              </Content>
            </Layout>
          </Sider>
        </Layout>
      </div>
    );
  }
}

export default DeviceMonitor;
