import React from "react";
import { Timeline, Tag, Tooltip } from "antd";
import MessageItem from "./MessageItem";

class MessageTimeline extends React.Component {
  getItemColor = message => {
    if (message.loadStatus === 1) {
      if (message.tripStatus === 5 && message.location === "") {
        // return "#108ee9";
        return "blue";
      }
      return "blue";
    } else {
      if (message.tripStatus === 5 && message.location === "") {
        // return "#f50";
        return "red";
      }
      return "red";
    }
  };
  getItemPosition = message => {
    return message.gpsAvailable ? "right" : "left";
  };
  getItemClassName = (message, activeMessage) => {
    let className = "message-item";
    if (activeMessage && activeMessage.seqNo === message.seqNo) {
      className += " active";
    }
    return className;
  };
  render = () => {
    const { messages, onMessageActive, activeMessage } = this.props;
    return (
      <Timeline mode="alternate">
        {messages.map(message => {
          return (
            <Timeline.Item
              color={this.getItemColor(message)}
              position={this.getItemPosition(message)}
              className={this.getItemClassName(message, activeMessage)}
              onMouseOver={() => onMessageActive(message)}
              dot={
                <Tooltip placement="top" title={message.data}>
                  <Tag color={this.getItemColor(message)}>
                    {message.adjustSeqNo}
                  </Tag>
                </Tooltip>
              }
              key={message.seqNo}
            >
              <MessageItem message={message}></MessageItem>
            </Timeline.Item>
          );
        })}
      </Timeline>
    );
  };
}

export default MessageTimeline;
