const { PureComponent } = require("react");

class Polyline extends PureComponent {
  componentWillUpdate() {
    this.line.setMap(null);
  }

  componentWillUnmount() {
    this.line.setMap(null);
  }

  render() {
    const paths = this.props.paths;
    let color = this.props.color ?? "#FF0000";

    this.line = new this.props.maps.Polyline({
      path: paths,
      geodesic: true,
      strokeColor: color,
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });

    this.line.setMap(this.props.map);
    return null;
  }
}

export default Polyline;
