import React from "react";
import DeviceMonitor from "../pages/DeviceMonitor";
import { Link, Route, Switch } from "react-router-dom";
import { Layout, Menu, Row, Col } from "antd";
import logo from "../asserts/logo.png";
import DnataMonitor from "../pages/DnataMonitor";

const { Header, Content } = Layout;

const App = () => (
  <div>
    <Layout>
      <Header
        style={{
          background: "#fff",
          position: "fixed",
          zIndex: 1,
          width: "100%",
        }}
      >
        <Row>
          <Col span={6}>
            <div>
              <img
                src={logo}
                height="32px"
                style={{ marginTop: "-8px" }}
                alt="logo"
              />
              <div
                style={{
                  marginLeft: "16px",
                  fontSize: "18px",
                  fontWeight: "500",
                  display: "inline",
                }}
              >
                Pallet Dolly Analysis
              </div>
            </div>
          </Col>
          <Col span={18}>
            <Menu
              mode="horizontal"
              defaultSelectedKeys={["1"]}
              align="right"
              theme="light"
              style={{ lineHeight: "64px", textAlign: "right" }}
            >
              <Menu.Item key="1">
                <span style={{ paddingLeft: 10, paddingRight: 10 }}>
                  Monitor
                </span>
                <Link to="/" />
              </Menu.Item>
              {/* <Menu.Item key="2">
                <span>SATS</span>
                <Link to="/sats" />
              </Menu.Item> */}
            </Menu>
          </Col>
        </Row>
      </Header>
      <Content style={{ marginTop: 64, background: "#fff" }}>
        <Switch>
          <Route exact path="/" component={DnataMonitor} />
          <Route path="/dnata" component={DnataMonitor} />
          <Route path="/sats" component={DeviceMonitor} />
        </Switch>
      </Content>
    </Layout>
  </div>
);

export default App;
